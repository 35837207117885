import Router from 'next/router'
import React, { Component, useContext } from 'react'

import { useSnippetContent, useSnippet } from '../SnippetProvider'
import { BackendApiContext } from '../BackendApiProvider'
import {
  trackAddToCart,
  trackCartItemQtyChange,
  trackCartItemRemoved,
  trackCouponCode,
  trackQtyChangeNew,
  trackSubscriptionToggle,
} from '../tracking'
import logError from '../logError'

const CartActionContext = React.createContext()
const CartContentContext = React.createContext()

///////////////////////////////////////////////////////////////////////
function useCartActions() {
  return useContext(CartActionContext)
}

function withCartActions(WrappedComponent) {
  return function WithCartActionContext(props) {
    const ctx = useCartActions()
    return <WrappedComponent cartActions={ctx} {...props} />
  }
}

///////////////////////////////////////////////////////////////////////
function useCartConfigOld() {
  const { globalVariables: vars = [] } =
    useSnippetContent('cart_page_configuration') || {}
  const config = {}
  vars.map((v) => {
    config[v.varName] = v.varValue
  })

  return config
}

function useCartConfig() {
  //return useSnippetContent('cart_page_configuration') || {} // TODO: enable when activating/deploying the new cart design
  return useSnippet('cart_page_configuration')?.[1]?.properties?.content || {}
}

function withCartConfig(WrappedComponent) {
  return function WithCartConfigContext(props) {
    const ctx = useCartConfig()
    return <WrappedComponent cartConfig={ctx} {...props} />
  }
}

///////////////////////////////////////////////////////////////////////
function useCartContent() {
  return useContext(CartContentContext)
}

function withCartContent(WrappedComponent) {
  return function WithCartContentContext(props) {
    const ctx = useCartContent()
    return <WrappedComponent cartContent={ctx} {...props} />
  }
}

///////////////////////////////////////////////////////////////////////
class CartProvider extends Component {
  static contextType = BackendApiContext

  constructor(props) {
    super(props)
    this.state = {
      // Cart content
      items: [],
      totals: [],
      couponCode: null,
      shippingOptions: {},
      paymentMethods: [],

      aboInterval: null,
      isAboActive: false,

      paypalShortcutId: null,
      reorderPaymentMethods: [],
      canCheckout: false,

      // Operational
      isLoading: false,
      errorMessage: null,
      successMessage: null,

      // Place to show messages.
      messageTarget: 'default',

      config: {
        aboCouponText: null,
        aboIntervalLabel: null,
        aboInfoUrl: null,
        cartItemAboCheckboxLabel: null,
        couponUsageInfo: null,
        active: {
          btnText: null,
          infoLines: null,
        },
        inactive: {
          btnText: null,
          infoLines: null,
        },
      },
    }
  }

  componentDidMount() {
    Router.events.on('routeChangeComplete', this.updateCart)
    // When logging in, the cart-ID changes
    window.addEventListener('customerinfo:login:changed', this.updateCart)
    this.updateCart()
  }

  componentWillUnmount() {
    Router.events.off('routeChangeComplete', this.updateCart)
    window.removeEventListener('customerinfo:login:changed', this.updateCart)
  }

  updateCart = async () => {
    const backendApi = this.context || {}

    this.setState({
      isLoading: true,
    })
    return backendApi
      .getCartData()
      .then((response) => {
        if (response.status === 'ok') {
          const {
            items,
            totals,
            couponCode,
            shippingOptions,
            paymentMethods,
            canCheckout,
            aboInterval = null,
            isAboActive = false,
            paypalShortcutId = null,
            reorderPaymentMethods = [],
          } = response
          this.setState(
            {
              items,
              totals,
              couponCode,
              shippingOptions,
              paymentMethods,
              canCheckout,
              aboInterval,
              isAboActive,
              paypalShortcutId,
              reorderPaymentMethods,
              isLoading: false,
            },
            () => {
              window.dispatchEvent(new Event('bubble:position:update'))
            }
          )
        } else {
          throw new Error(response.message || response)
        }
        return response
      })
      .catch((error) => {
        console.error(error)
        logError(error, 'updateCart')
        this.setState({
          isLoading: false,
          errorMessage: error.message,
          successMessage: null,
        })
      })
  }

  performAction = async (callback, options = {}) => {
    const { onSuccess, onError, onFinally, messageTarget = 'default' } = options
    if (typeof callback !== 'function') {
      throw Error('Invalid callback')
    }

    this.setState({ isLoading: true })

    let newState = { ...this.state } // Copy
    let response
    try {
      response = await callback()

      const { status, message, ...rest } = response
      newState = {
        ...this.state,
        successMessage: status === 'ok' ? message : null,
        errorMessage: status !== 'ok' ? message : null,
        messageTarget,
        ...rest,
      }
      if (response.status === 'ok') {
        if (typeof onSuccess === 'function') {
          onSuccess(response)
        }
      } else {
        if (typeof onError === 'function') {
          onError(response)
        }
      }
    } catch (error) {
      console.error(error)
      response = {
        status: 'error',
        message: error,
      }
      if (typeof onError === 'function') {
        onError(response)
      }
    }
    newState.isLoading = false
    this.setState(newState, () => {
      window.dispatchEvent(new Event('bubble:position:update'))
    })

    if (typeof onFinally === 'function') {
      onFinally(response)
    }
    return response
  }

  addItem = async ({
    productData,
    qty,
    selectedOptions,
    isAboEnabled,
    couponCode,
  }) => {
    const backendApi = this.context || {}

    const requestEvent = new CustomEvent('addtocart:request', {
      detail: { productData },
    })
    window.dispatchEvent(requestEvent)

    return this.performAction(
      async () => {
        return backendApi.addToCart({
          productData,
          qty,
          selectedOptions,
          isAboEnabled,
          couponCode,
        })
      },
      {
        onSuccess: (response) => {
          // E.g. Quickview popup
          const evt = new CustomEvent('addtocart:success', { detail: response })
          window.dispatchEvent(evt)
        },
        onError: (response) => {
          const data = {
            sku: productData.sku,
            ...response,
          }
          logError(data, 'addToCart')
          const evt = new CustomEvent('addtocart:error', { detail: response })
          window.dispatchEvent(evt)
        },
        onFinally: (response) => {
          // E.g. Infobox popup
          const evt = new CustomEvent('addtocart:response', {
            detail: response,
          })
          window.dispatchEvent(evt)
        },
      }
    )
  }

  removeItem = async (cartProduct, oldQty) => {
    return this.setItemQty(cartProduct, oldQty, 0)
  }

  setItemQty = async (cartProduct, oldQty, newQty) => {
    const backendApi = this.context || {}
    const { items: itemsBefore, totals } = this.state

    // Track the difference
    const trackDifference = false // Disabled intentionally
    const qtyBefore =
      itemsBefore.find((item) => cartProduct.id === item.product.id)?.qty || 0
    const qtyDiff = Math.max(newQty, qtyBefore) - Math.min(newQty, qtyBefore)

    return this.performAction(
      async () => {
        return backendApi.setCartItemQty({ cartProduct, qty: newQty })
      },
      {
        onSuccess: ({ items: itemsAfter }) => {
          if (trackDifference) {
            if (newQty < qtyBefore) {
              trackQtyChangeNew(cartProduct, qtyDiff)
            } else if (newQty > qtyBefore) {
              const grandTotal = totals?.find(
                (t) => t.code === 'grand_total'
              )?.value
              trackAddToCart(
                cartProduct,
                qtyDiff,
                grandTotal,
                itemsAfter,
                cartProduct.price
              )
            }
          }
          if (newQty === 0) {
            trackCartItemRemoved(cartProduct, itemsAfter, totals, oldQty)
          } else {
            trackCartItemQtyChange(cartProduct, newQty)
          }
        },
      }
    )
  }

  setAboItem = async (cartProduct, isAboEnabled) => {
    const backendApi = this.context || {}

    return this.performAction(
      async () => {
        return backendApi.setCartItemAboStatus({ cartProduct, isAboEnabled })
      },
      {
        onSuccess: () => {
          trackSubscriptionToggle(
            cartProduct.sku,
            cartProduct.aboPrice,
            isAboEnabled
          )
        },
      }
    )
  }

  setCouponCode = async (couponCode) => {
    const backendApi = this.context || {}

    return this.performAction(
      async () => {
        return backendApi.setCouponCode({ couponCode })
      },
      {
        messageTarget: 'coupon',
        onSuccess: ({ message }) => {
          trackCouponCode({
            coupon: couponCode,
            action: message,
          })
        },
      }
    )
  }

  removeAboCoupon = async () => {
    const backendApi = this.context || {}
    return this.performAction(backendApi.removeAboCoupon)
  }

  setShippingOption = async (key, value) => {
    const backendApi = this.context || {}

    return this.performAction(async () => {
      return backendApi.setShippingOptions({
        [key]: value,
      })
    })
  }

  render() {
    const {
      isLoading,
      errorMessage,
      successMessage,
      config,
      messageTarget,
      ...cartContent
    } = this.state

    const actions = {
      addItem: this.addItem,
      removeItem: this.removeItem,
      setItemQty: this.setItemQty,
      setCouponCode: this.setCouponCode,
      setAboItem: this.setAboItem,
      removeAboCoupon: this.removeAboCoupon,
      setShippingOption: this.setShippingOption,
      isLoading,
      errorMessage,
      successMessage,
      messageTarget,
    }

    return (
      <CartActionContext.Provider value={actions}>
        <CartContentContext.Provider value={cartContent}>
          {this.props.children}
        </CartContentContext.Provider>
      </CartActionContext.Provider>
    )
  }
}

export default CartProvider
export {
  useCartActions,
  useCartConfig,
  useCartConfigOld,
  useCartContent,
  withCartActions,
  withCartConfig,
  withCartContent,
}
